import NavBar from "./NavBar"
import MerchItem from "./MerchItem"

const Merch = ({merch}) => {

    const merchItems = merch.map(r => <MerchItem key={r.id} {...r} />)

    return (
        <div>
            <NavBar />
            <div className='min-h-screen flex flex-row text-white font-semibold justify-center'>
                <div className='sm:py-32 lg:py-24'>
                    {merchItems}
                </div>
            </div>
        </div>
    )
}

export default Merch