import {NavLink} from 'react-router-dom'

const LandingPage = () => {
    return (
        <div className='relative min-h-screen flex flex-col justify-center items-center text-white'>
            <div className='text-2xl font-bold flex flex-col justify-center items-center'>
                <NavLink to='/music' className='duration-300 ease-in-out hover:drop-shadow-[0_0_20px_rgba(255,255,255)]'>MUSIC</NavLink>
                <NavLink to='/videos' className='duration-300 ease-in-out hover:drop-shadow-[0_0_20px_rgba(255,255,255)]'>VIDEOS</NavLink>
                <NavLink to='/live' className='duration-300 ease-in-out hover:drop-shadow-[0_0_20px_rgba(255,255,255)]'>LIVE</NavLink>
                <NavLink to ='/merch' className='duration-300 ease-in-out hover:drop-shadow-[0_0_20px_rgba(255,255,255)]'>MERCH</NavLink>
                </div>
        </div>
    )
}


export default LandingPage
