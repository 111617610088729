import LandingPage from "./components/LandingPage";
import {Route, Switch} from 'react-router-dom';
import Footer from "./components/Footer";
import Music from './components/Music'
import Videos from "./components/Videos";
import Live from "./components/Live";
import Merch from "./components/Merch";
import ponyCover from './images/TOAST5.jpeg'
import { useEffect, useState } from "react";
import instance from "./components/config/axiosConfig";

function App() {

  const [merchList, setMerchList] = useState([])
  const [session, setSession] = useState(null)
  // const [cart, setCart] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!session) {
          await createSession()
        }

        const r = await instance.get('/merch')
        // const c = await instance.get('/cart/items')

        setMerchList(r.data)
        // setCart(c.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    const createSession = async () => {
      try {
          const r = await instance.get('/session')
          setSession(r.data)
        } catch (error) {
          console.error('Error creating session:', error)
        }
      }
      fetchData()
    },[session])

  return (
    <div className='min-h-screen flex flex-col relative'>
      <div className='flex-grow flex flex-col bg-fixed bg-cover bg-center'>
        <Route exact path ='/'>
          <LandingPage />
        </Route>
        <Switch>
          <Route path='/music'>
            <Music ponyCover={ponyCover} />
          </Route>
          <Route path='/videos'>
            <Videos />
          </Route>
          <Route path='/live'>
            <Live />
          </Route>
          <Route path='/merch'>
            <Merch merch={merchList} />
          </Route>
        </Switch>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
