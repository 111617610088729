import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://toast-server.onrender.com',
  // baseURL: 'http://127.0.0.1:5555/',
  headers: {
    "Content-Type": 'application/json'
  }
});

export default instance;
