import { useState } from "react"
import instance from "./config/axiosConfig"

const MerchItem = ({price, id, images, name, sizes}) => {
    const [imageIndex, setImageIndex] = useState(0)
    const [size, setSize]= useState(null)

    const handleNextImage = () => {
        setImageIndex(() => (imageIndex + 1) % images.length)
    }
    const handlePrevImage = () => {
        setImageIndex(() => (imageIndex - 1 + images.length) % images.length)
    }
    const handleSizeSelect = (s) => {
        setSize(prevSize => prevSize === s ? null : s)
    }
    const handleBuy= async () => {
        if (!size) {
            alert('Please select a size.')
            return
        }

        try {
            const r = await instance.post('/checkout', {
                id : id,
                size : size
            })
            window.location.href = r.data.url;
        } catch (error) {
            console.error('Error creating payment intent:', error)
            alert('There was an error processing your payment. Please try again.')
        }
    }

    const formattedPrice = price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    return (
        <div className='my-10 p-8 w-full flex flex-row mx-auto'>
            <div className='flex flex-col w-1/2 items-center'>
                <div className='mt-2 w-full overflow-hidden'>
                    <img className='h-48 md:h-72 mx-auto' src={images[imageIndex].url} alt={images[imageIndex].item.name}/>
                </div>
                <div className='items-center text-xl mb-2'>
                    <button className='border mx-2 px-1 hover:bg-white hover:text-green-800' onClick={handlePrevImage}>PREV</button>
                    <button className='border mx-2 px-1 hover:bg-white hover:text-green-800' onClick={handleNextImage}>NEXT</button>
                </div>
            </div>
            <div className='flex flex-col w-1/2 items-center justify-center'>
                <div className='h-1/2 w-full flex flex-col items-center '>
                    <p className='flex mt-2 md:mt-6 m-2 p-0 md:p-2 text-xl md:text-3xl text-center'>{name}</p>
                    <p className='p-0 md:p-2 text-md md:text-2xl'>{formattedPrice}</p>
                </div>
                <div className='flex flex-col pt-2 items-center'>
                    <div className='flex flex-row pb-2'>
                        {sizes && sizes.length === 1 ? (
                            <button 
                                className={`border px-2 md:px-4 md:py-2 text-lg md:text-xl hover:bg-white hover:text-green-800 ${size === sizes[0].size ? 'bg-green-800 text-white' : ''}`}
                                onClick={() => handleSizeSelect(sizes[0].size)} 
                            >{sizes[0].size}
                            </button>
                        ) : sizes && sizes.length > 1 ? (
                            sizes.map((s, index) => (
                                <button 
                                    key={index}
                                    className={`border px-2 md:px-4 md:py-2 m-1 md:m-2 text-lg md:text-xl hover:bg-white hover:text-green-800 ${size === s.size ? 'bg-green-800 text-white' : ''}`}
                                    onClick={() => handleSizeSelect(s.size)}    
                                >
                                    {s.size}
                                </button>
                            ))
                        ) : null}
                    </div>
                    <button onClick={handleBuy} className='flex border rounded-xl m-4 px-4 bg-green-800 text-xl md:text-2xl'>BUY</button>
                </div>
            </div>
        </div>
    )
}

export default MerchItem
