import NavBar from "./NavBar"

const Videos = () => {
    const videoIds = ['-Iu3Am0ibww','qVz0I2Eiw4g', 'VsDXPLuWozU', 'vjahq9x_ZMo', 'nzWR1_bqNjU', 't2mHXDaFE3Y', 'O9VrD2Cy2Fg',]

    return (
        <div className='relative min-h-screen flex flex-col items-center text-white'>
            <NavBar />
            <div className='mt-16 mb-14'>
                {videoIds.map((videoId, index) => (
                    <iframe class='w-full aspect-video sm:h-32 md:h-96 mt-4 relative'
                        title={`YouTube Video ${index + 1}`}
                        src={`https://www.youtube.com/embed/${videoId}`}
                        loading="lazy"
                        allowFullScreen
                    ></iframe>
                ))}
            </div>
        </div>
    )
}

export default Videos