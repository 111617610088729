import NavBar from "./NavBar"

const Live = () => {
    return (
        <div className='relative min-h-screen flex flex-col justify-center items-center text-white'>
            <NavBar />
            <div className='text-2xl font-bold flex flex-col justify-center items-center'>
                <h1 className='text-4xl pt-3 pb-1 border-b'>TOUR DATES</h1>                
                <h1 className='pt-2'>Stay Tuned for Upcoming Dates</h1>
            </div>
        </div>
    )
}

export default Live