import NavBar from "./NavBar"

const Music = ({ponyCover}) => {
    return (
        <div className='relative min-h-screen flex flex-col justify-center items-center text-white'>
            <NavBar />
            <div className='h-screen flex flex-col justify-center items-center'>
                <a href='https://hypeddit.com/toast/pony' target='_blank' rel='noopener noreferrer'>
                    <img src={ponyCover} alt='Pony Album Cover' className='h-96 py-4'/>
                </a>
                <h1 className='self-center text-2xl font-bold border-b-2'>"PONY" THE ALBUM OUT NOW</h1>
            </div>
            <div className='mb-14 max-w-full sm:max-w-lg lg:max-w-2xl'>
                <iframe className='py-2'
                    title='Pony'
                    src="https://open.spotify.com/embed/album/3S1hCbdg3ytiystyw199cw?utm_source=generator&theme=0" 
                    width='300' 
                    height='425' 
                    allowTransparency='true'
                    allow='encrypted-media'>
                </iframe>
            </div>
        </div>
    )
}

export default Music