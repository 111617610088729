import {NavLink} from 'react-router-dom'

const NavBar = () => {

    return (
        <div className='absolute top-0 left-0 right-0 flex justify-center text-white font-bold py-4'>
            <ul className='text-lg lg:text-2xl flex flex-row'>
                <li className='px-2'>
                    <NavLink to='/'>HOME</NavLink>
                </li>
                <li className='px-2'>
                    <NavLink to='/music'>MUSIC</NavLink>
                </li>
                <li className='px-2'>
                    <NavLink to='/videos'>VIDEOS</NavLink>
                </li>
                <li className='px-2'>
                    <NavLink to='/live'>LIVE</NavLink>
                </li>
                <li className='px-2'>
                    <NavLink to='merch'>MERCH</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default NavBar